






import { MODULES } from '@/constants';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class HomepageView extends Vue {
    mounted(): void {
        this.$router.push({
            name: MODULES.URL_CACHE_CHECKER.ROUTE.NAME.URL_CACHE,
        });
    }
}
